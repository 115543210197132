/*Match payroll********************** */

.btn001 {
    border-radius: 4px;
    box-shadow: 0 0 0 1px
    rgba(50,50,93,.08), 0 2px 5px 0 rgba(50,50,93,.06), 0 1px 1.5px 0 rgba(0,0,0,.05), 0 1px 2px 0 rgba(0,0,0,.06);
    display:block;
    float:right;
    color:#2a2f45;
    position: absolute;
    right: 16px;
    height: 26px;
    padding: 0 15px;
    line-height: 27px;
    top: 17px;
}
.select-dropdown{
    font-family: fontawesome;
    border-radius: 4px !important;
}
.select-dropdown::after{
    content:"\f0dc" !important;
}
.react-dropdown-select-content{
    font-family: Roboto, sans-serif;
}
.btn001 span,
.btn001 i {
    color:#2a2f45;
}
@media (max-width:1023px) {
    .btn001 {
        top: 5%;
    }
}
