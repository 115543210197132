.bill-data table th {
  font-size: 12px;
  color: #4c4c4c;
  font-weight: 600;
  background: #f6f7fb;
  padding: 1px 15px;
  text-align: left;
  text-transform: uppercase;
  border-bottom: solid 1px #ddd;
}

.bill-data table td {
  padding: 1px 15px;
  color: #5f5f5f;
  font-size: 14px;
  height: 36px;
}
.approved-col,
.excluded-col,
.sending-col,
.plain-col,
.failed-col {
  display: block;
  background: #d2e9fb;
  color: #37598c;
  width: 150px;
  text-align: center;

  line-height: 24px;

  border-radius: 4px;

  font-size: 13px;
}
.excluded-col {
  background: #fbeabe;
}
.failed-col {
  background: #958c9e61;
  color: red;
}
.sending-col {
  background: #dddddd;
}
.plain-col {
  background: #fff;
  border: solid 1px #ededf1;
}
.fa-ellipsis-h {
  font-size: 20px;
  color: #7f7f7f;
}
.ellipis-btn {
  display: block;
  height: 30px;
  text-align: center;
  padding: 5px 10px;
  background: #fff;
  line-height: 20px;
  border-radius: 4px;
}
.ellipis-btn i {
  display: block;
  background: #7f7f7f;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  line-height: 30px;
  position: relative;
  top: 8px;
  float: left;
  margin-left: 3px;
}
.ellipis-btn i:nth-of-type(1) {
  margin-left: 0;
}
.ellipis-btn:hover {
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.hide-items {
  background: #fff;
  width: 190px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  padding: 10px 0;
  border-radius: 4px;
  top: 40px;
  display: none;
  z-index: 99;
}
.show-items {
  background: #fff;
  width: 190px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  padding: 10px 0;
  border-radius: 4px;
  top: 40px;
  display: block;
  z-index: 99;
}
ul.dot-list li {
  position: relative;
  cursor: pointer;
}
ul.dot-list ul li span {
  display: block;
  line-height: 30px;
  color: #37598c;
  padding: 0 15px;
}
ul.dot-list ul li span:hover {
  background: #f6f7fb;
}
ul.dot-list ul a {
  display: block;
  line-height: 30px;
  color: #37598c;
  padding: 0 15px;
}
ul.dot-list ul a:hover {
  background: #f6f7fb;
}
ul.dot-list ul::before,
ul.dot-list ul::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #eceeef transparent;
  position: absolute;

  z-index: 999;
  right: 11px;
  top: -10px;
}
ul.dot-list ul::after {
  border-width: 0 11px 11px 11px;
  border-color: transparent transparent #fff transparent;
  top: -9px;
  right: 10px;
}
ul.dot-list li:hover > a {
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
ul.dot-list li li a:hover {
  box-shadow: none;
}
.bill-data {
  height: auto;
  display: block;
  width: 100%;
}
.bill-data [type="checkbox"]:not(:checked) + label::before,
.bill-data [type="checkbox"]:checked + label::before {
  top: -6px;
}
.bill-data [type="checkbox"]:not(:checked) + label::after,
.bill-data [type="checkbox"]:checked + label::after {
  top: -5px;
}
.btn-col {
  display: block;
  text-align: right;
  margin: 20px 0;
}
.btn-col .btn {
  margin-left: 10px;
}
.bill-data tr td {
  border-bottom: solid 1px #ddd;
  padding-bottom: 1px;
  padding-top: 0;
}
ul.bulk-action-list {
  float: left;
}

ul.bulk-action-list ul {
  right: auto;
  left: 0;
}
ul.bulk-action-list ul::after {
  right: auto;
  left: 11px;
}
ul.bulk-action-list ul::before {
  right: auto;
  left: 10px;
}
.select-field {
  float: left;
  position: relative;
}

.btn-block-top {
  margin-bottom: 10px;
}
.filter-block ul ul {
  width: 250px;
  background: #f6f7fb;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0;
  font-size: 12px;
  padding: 10px 0;
  border-radius: 4px;
  top: 40px;
  z-index: 99;
}
.filter-block {
  float: left;
  margin: 0 0 0 20px;
}
.filter-block ul li {
  position: relative;
}

.filter-block ul li button {
  padding: 0 15px;
  line-height: 27px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 5px 0 rgba(50, 50, 93, 0.06), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.col001 {
  margin: 0;
}
.accordion {
  width: 100%;
}
.accordion > .card {
  overflow: visible;
}
.card-header {
  padding: 0;
}
h2.mb-0 {
  width: 100%;
  background: #fff;
  text-align: left;
}
/*h2.mb-0 [type="checkbox"]:checked + label::before,
h2.mb-0 [type="checkbox"]:not(:checked) + label::before {
	top:-7px;}
h2.mb-0 [type="checkbox"]:checked + label::after {
	 top:-6px;
	}*/
h2.mb-0 [type="checkbox"]:not(:checked) + label,
h2.mb-0 [type="checkbox"]:checked + label,
.btn-link {
  color: #4c4c4c;
  font-size: 14px;
}
/*.filter-block .btn-bl {*/
/*  border:none;*/
/*}*/
.accordion {
  margin-top: 10px;
}
.card-body {
  background: #f6f8fc;
}
.filter-block ul ul {
  display: block;
  top: 29px;
}
/*.filter-block ul li:hover ul {*/
/*  display:block;*/
/*}*/
.btn-block-top > ul {
  float: left;
}
.btn-block-top > ul > li > span {
  font-size: 14px;
  color: #17598f;
  padding: 0 25px 0 15px;
  line-height: 26px;
  border-radius: 4px;
  display: block;
  position: relative;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 5px 0 rgba(50, 50, 93, 0.06), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.btn-block-top > ul > li > span::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4.5px 0 4.5px;
  border-color: #555555 transparent transparent transparent;
  position: absolute;
  right: 10px;
  top: 12px;
}

.btn-block-top > ul > li > ul {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  display: none;
}

.show {
  display: block !important;
}
/*.btn-block-top > ul > li:hover ul {
  display:block;
}*/

.btn-block-top > ul > li > ul > li > button {
  font-size: 13px;
  color: #7f7f7f;
  line-height: 25px;
  padding: 1px;
}
.btn-block-top > ul > li > ul > li > button:hover {
  color: #17598f;
}
.filter-block ul li a {
  color: #17598f;
}
.filter-block ul li a.btn-bl {
  color: #fff;
}
.sub-title {
  text-align: center;
  margin: auto;
  display: block;
  color: #232b44;
}

.filter-block [type="checkbox"]:not(:checked) + label::before,
.filter-block [type="checkbox"]:checked + label::before {
  top: 3px;
}
.filter-block [type="checkbox"]:not(:checked) + label::after,
.filter-block [type="checkbox"]:checked + label::after {
  top: 3px;
}
.card-body {
  padding-bottom: 10px;
}

/*Integration future*/

.dot-list {
  max-width: 50px;
}
.installed-integrations {
  min-height: 200px;
}
ul.logo-block {
  margin: 20px 0;
}
ul.logo-block li {
  display: inline-block;
  margin-right: 30px;
  width: auto;
  height: 60px;

  margin-bottom: 20px;
  vertical-align: top;
}
ul.logo-block li span {
  display: block;
  text-align: center;
  color: #7f7f7f;
  line-height: 30px;
  margin-top: 10px;
}
ul.logo-block li img {
  width: auto;
  height: 100%;
}
.title-block {
  background: #e6ecf2;
  font-size: 16px;
  color: #20221d;
  padding: 10px;
  font-weight: 400;
}
.installed-integrations .dot-list {
  float: right;
}
.filter-button-margin {
  margin-left: 4px;
  margin-top: -6px;
}
/* */
.bs-FilterControl-input-container {
  background: url("../images/logo/img.svg") no-repeat 4px 5px;
  background-size: 12px;
  margin-top: 10px;
  padding-left: 28px;
}
.bs-FilterControl-inTheLast-container {
  display: flex;
}
.bs-FilterControl-textInput {
  border: 1px solid #aaa;
  border-radius: 1px;
  border-bottom-color: #ccc;
  border-right-color: #ccc;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 12px;
  padding: 3px 4px;
  transition: box-shadow 0.1s ease-in-out;
}
.bs-FilterControl-inTheLast-container .bs-FilterControl-textInput {
  margin-right: 4px;
  width: 30px;
}
.bs-FilterControl-singleDateInput-container {
  display: inline-block;
}
.bs-FilterControl-textInput .bs-FilterControl-dateInput {
  background-position: 6px 4px;
  width: 84px;
}
/* */
.title-001 {
  font-size: 12px;
  font-weight: 500;
}
.select-col select {
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 14px;
  box-shadow: none;
  border: none;
  height: 36px;
}
input.form-control,
select.form-control {
  line-height: 36px;
  height: 36px;
  padding: 0 15px;
}
.select-col {
  position: relative;
  border: solid 1px #e1e1e4;
  border-radius: 4px;
  margin-bottom: 10px;
}
.select-col::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7.5px 0 7.5px;
  border-color: #808080 transparent transparent transparent;
  position: absolute;
  right: 10px;
  top: 16px;
}
.is-between .col-md-2 {
  padding: 5px;
  text-align: center;
}
.is-between input.form-control,
.date-col input {
  font-size: 12px;
  height: 29px;
  line-height: 29px;
  padding-left: 23px;
  width: 84px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.is-between .l-col {
  padding-right: 0;
}
.is-between .r-col {
  padding-left: 0;
}
.date-col {
  position: relative;
}
.date-col::before {
  content: "\f073";
  font-family: FontAwesome;
  position: absolute;
  left: 10px;
  top: 4px;
  color: #808080;
  font-size: 12px;
}
.filter-block [type="checkbox"]:not(:checked) + label::before,
.filter-block [type="checkbox"]:checked + label::before {
  top: 1px;
}
.min-h-40 {
  line-height: 40px;
}
.form-check-input {
  margin-top: 0.2rem !important;
}
.date-height {
  height: calc(1.5em + 0.5rem + 2px) !important;
}
@media (max-width: 600px) {
  .filter-block ul ul {
    width: 280px;
    left: 50%;
    right: 50%;
    margin-left: -37px;
  }
  ul.logo-block li .int-logo {
    height: 35px;
  }
}

/* */
.react-datepicker__input-container {
  position: unset !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__navigation {
  line-height: 1.7rem !important;
  cursor: pointer !important;
  width: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.qbBilling {
  font-size: 14px;
  color: #ffffff;
  padding: 0 25px 0 15px;
  line-height: 24px;
  border-radius: 4px;
  display: block;
  position: relative;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 5px 0 rgba(50, 50, 93, 0.06), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #37598c;
  border-color: #37598c;
  margin-left: -15%;
}
.link-font {
  color: #007bff !important;
}
.cursor-pointer {
  cursor: pointer;
}
