.vertical-center {
    display: block;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}
.min-height-200{
    min-height: 450px;
}
.cursor-pointer{
    cursor: pointer;
}
.text-error{
    font-size: 115%;
    color: #dc3545
}
.page-item.active .page-link{
    background-color:#37598c ;
    border-color: #37598c;
}
.page-link{
    color: #37598c;
}
.h-35{
    height: 35px !important;
}
.empty-page{
    font-weight: 500;
    font-size: 16px;
}
.text-color-gray{
    color: #697386;
}
.Toastify__toast--success{
    background-color: #37598c !important;
}
.react-dropdown-select-item{
    padding: 3px 10px !important;
    font-family: Roboto, sans-serif;
}
.react-dropdown-select{
    min-height: 29px !important;
    padding: 0 4px !important;
}
.react-dropdown-select-dropdown{
    width: inherit !important;
}
.font-11{
    font-size: 11px;
}
.top-down {
    top: 90%
}
.top-up {
    top: 53%
}